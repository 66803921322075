.home-contato-form {
  background: $cor_fundo2;
  border: 1px solid darken($cor_fundo2, 5%);
  padding: 40px;

  // MOBILE
  @include mq(s) {
    padding: 20px;
    margin-bottom: 20px;
  }
}

.home-mapa {
  border: 1px solid darken($cor_fundo2, 5%);
  overflow: hidden;
  iframe {
    height: 588px !important;
    margin-bottom: -8px;

    // TABLET
    @include mq(m) {
      height: 771px !important;
    }

    // MOBILE
    @include mq(s) {
      width: 100% !important;
      height: auto;
    }
  }
}
