// Fotos
.produto-detalhe-fotos {
  position: relative;

  // TABLET
  @include mq(m) {
    margin-bottom: 20px;
  }

  // MOBILE
  @include mq(s) {
    margin-bottom: 20px;
  }
}

// Carrosel destaque
.produto-detalhe-fotos-destaque {
  text-align: center;
  background: $cor_fundo2;
  max-height: 600px;
  overflow: hidden;

  // MOBILE
  @include mq(s) {
    max-height: 260px;
  }

  // Modifica as cetas do SLICK para o slide
  .slick-prev,
  .slick-next {
    width: 80px;

    // TABLET
    @include mq(m) {
      width: 40px;
    }

    &::before {
      height: 30px;
      width: 30px;

      // MOBILE
      @include mq(s) {
        height: 20px;
        width: 20px;
      }
    }
  }
  .slick-dots {
    bottom: 10px;
  }

  img {
    max-width: initial;
    max-height: 600px;
    display: inline-block;

    // MOBILE
    @include mq(s) {
      max-height: 260px;
    }
  }

  iframe {
    width: 100% !important;
    height: 600px !important;

    // MOBILE
    @include mq(s) {
      height: 260px !important;
    }
  }
}

// Carrosel thumbs
.produto-detalhe-fotos-thumbs {
  margin-top: 10px;

  // Thumb
  li {
    margin: 0 5px;
    border: 2px solid #fff;
    overflow: hidden;
    cursor: pointer;
    transition: 0.3s ease-out;

    &:hover {
      border-color: $cor_fundo2;

      img {
        transform: scale(1.1);
      }
    }

    // Ativa
    &.slick-current {
      border-color: $cor_primaria;
    }

    // Foto
    img {
      transition: 0.5s ease-out;
    }
  }
}

// Texto
.produto-detalhe-texto {
  padding-left: 10px;

  // TABLET
  @include mq(m) {
    padding-left: 0;
  }

  // MOBILE
  @include mq(s) {
    padding-left: 0;
  }

  // BOTÃO
  .btn-container {
    float: none;

    // MOBILE
    @include mq(s) {
      text-align: center;
    }
  }
}
